import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import {getLegacyFileClassLabel} from '../../Utils/SelectOptionLabelUtil';

function AdminLegacyFileField(props) {
  const {formData, schema} = props;
  const [url, setUrl] = React.useState(null);

  React.useEffect(() => {
    async function fetchUrl({path, filename, ext}) {
      try {
        AppActions.setLoading(true);

        const resp = await AppActionsEx.postLegacyToReadFile({
          path,
          filename,
          ext,
        });

        setUrl(resp.url);
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    }

    if (
      !url &&
      !!formData &&
      Object.keys(formData).length &&
      formData.FILE_PATH &&
      formData.FILE_NAME &&
      formData.FILE_EXT
    ) {
      fetchUrl({
        path: formData.FILE_PATH,
        filename: formData.FILE_NAME,
        ext: formData.FILE_EXT,
      });
    }
  }, [
    url,
    formData,
    formData.FILE_PATH,
    formData.FILE_NAME,
    formData.FILE_EXT,
  ]);

  if (!url) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <div>{schema.title}</div>
      {url && (
        <div className="field">
          <label className="">
            {getLegacyFileClassLabel(formData?.FILE_CLASS || '')}
          </label>
          <label>{formData?.FILE_SHOWNAME || '檔案'}</label>

          <a href={url} target="_blank" rel="noreferrer">
            查看檔案
          </a>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .field {
    display: flex;
    align-items: center;
    border: 1px solid var(--adminBorderColor);
    border-radius: 4px;
    padding: 4px 11px;

    & > label {
      margin-right: 10px;
    }
  }

  & > div {
    padding-bottom: 8px;
    line-height: 1.5715;
  }
`;

export default AdminLegacyFileField;
